import Button from '@/components/Button';
import { format } from 'date-fns';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'DeliveryWeekActivateButton',
  props: {
    showDiscount: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const {
      isSelectedDeliveryWeekPauseEditable,
      selectedDeliveryWeek,
      selectedDeliveryWeekNumberString,
      selectedDeliveryWeekDiscountResolvedValue,
      selectedDeliveryWeekDiscount,
      selectedDeliveryWeekDiscountType,
      deliveryDate,
    } = useDeliveryWeeks();
    const { subscriptionId } = useCustomerSubscriptions();
    const { setWeekPause } = useDeliveriesApi();
    const noticeStore = useNoticeStore();
    const { t } = useI18n();
    const { formatPrice } = useCustomerCurrency();
    const discountAmount = computed<number>(() => {
      return selectedDeliveryWeekDiscount.value;
    });

    const handleDeliveryWeekActivate = async (event: Event): Promise<void> => {
      event.preventDefault();
      if (
        !subscriptionId.value ||
        !selectedDeliveryWeek.value ||
        !isSelectedDeliveryWeekPauseEditable.value
      ) {
        return;
      }

      try {
        await setWeekPause(
          false,
          subscriptionId.value,
          new Date(selectedDeliveryWeek.value.paymentDate)
        );

        noticeStore.addNotice({
          text: t('week_banner.delivery_x_activated', {
            deliveryDate: deliveryDate
              ? format(new Date(deliveryDate.value), 'd.M')
              : '',
          }),
          type: 'success',
        });
      } catch (_error) {
        noticeStore.addNotice({
          text: t('week_banner.delivery_x_update_failed', {
            deliveryDate: deliveryDate
              ? format(new Date(deliveryDate.value), 'd.M')
              : '',
          }),
          type: 'caution',
        });
      }
    };

    const getDiscountTexts = computed(() => {
      if (props.showDiscount && discountAmount.value !== 0) {
        return t('week_banner.activate_and_save_x', {
          discountAmount:
            selectedDeliveryWeekDiscountType.value === 'percentage'
              ? `${selectedDeliveryWeekDiscountResolvedValue.value} %`
              : formatPrice(discountAmount.value || 0),
        });
      }

      return t('week_banner.activate_delivery_x', {
        deliveryDate: deliveryDate.value
          ? format(new Date(deliveryDate.value), 'd.M')
          : '',
      });
    });

    return {
      handleDeliveryWeekActivate,
      selectedDeliveryWeek,
      selectedDeliveryWeekNumberString,
      discountAmount,
      deliveryDate,
      getDiscountTexts,
    };
  },
  render(): VNode {
    return (
      <Button class="button-primary" onClick={this.handleDeliveryWeekActivate}>
        {this.getDiscountTexts}
      </Button>
    );
  },
});
